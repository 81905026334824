/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Nov 08 2024
 */

<template>
  <button
    v-show="scY > scrollDistance"
    ref="buttonRef"
    class="tooltip tooltip-left fixed bottom-4 right-4 z-11 size-fit
    rounded bg-violet-500 p-3 shadow-sm hover:bg-violet-600"
    data-tip="Scroll to top"
    @click="toTop"
  >
    <span>
      <ArrowUpIcon
        class="size-6 text-white"
      />
    </span>
  </button>
</template>

<script setup lang="ts">

import {
  ArrowUpIcon,
} from '@heroicons/vue/24/outline';
import {
  inject,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import {
  SCROLL_DIV_REF,
} from '@/helpers';

defineProps({
  /**
   * In pixel
   */
  scrollDistance: {
    type: Number,
    default: 300,
  },
});

let scTimer = 0;
let el: HTMLDivElement | Window = window;
let scrollHandler: ()=> void;

const scY = ref(0);
const buttonRef = ref<HTMLButtonElement | undefined>();

function toTop(): void {
  el.scrollTo({
    top: 0, behavior: 'smooth',
  });
}

onMounted(() => {
  const scrollDivRef = inject(SCROLL_DIV_REF);
  const buttonEl = buttonRef.value;

  const _setBtnPosition = (): void => {
    if (!(el instanceof HTMLDivElement) || !buttonEl) {
      return;
    }
    const containerRect = el.getBoundingClientRect();
    const bottom = window.innerHeight - containerRect.bottom + 12;
    const right = window.innerWidth - containerRect.right + 12;

    buttonEl.style.bottom = `${Math.round(bottom)}px`;
    buttonEl.style.right = `${Math.round(right)}px`;
  };

  if (scrollDivRef?.value) {
    el = scrollDivRef.value;
    _setBtnPosition();
  }

  scrollHandler = (): void => {
    if (scTimer) {
      return;
    }
    scTimer = window.setTimeout(() => {
      scY.value = el instanceof HTMLDivElement ? el.scrollTop : el.scrollY;

      clearTimeout(scTimer);
      scTimer = 0;
    }, 100);
  };

  el.addEventListener('scroll', scrollHandler);
});

onUnmounted(() => {
  el.removeEventListener('scroll', scrollHandler);
});

</script>
