/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Nov 12 2024
 */

// 'root' + 'site-admin'
export const UserList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-list" */
  '@/views/Admin/UserList.vue'
);
export const MutateUser = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-user" */
  '@/views/Admin/MutateUser.vue'
);

// 'course-admin' + 'site-admin'
export const StudentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "student-list" */
  '@/views/Admin/StudentList.vue'
);
export const EnrollmentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "enrollment-list" */
  '@/views/Admin/EnrollmentList.vue'
);
export const CouponList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "coupon-list" */
  '@/views/Admin/CouponList.vue'
);
export const MutateCoupon = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-coupon" */
  '@/views/Admin/MutateCoupon.vue'
);
export const NewsList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "news-list" */
  '@/views/Admin/NewsList.vue'
);
export const MutateNews = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-news" */
  '@/views/Admin/MutateNews.vue'
);
export const HomeBannerList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home-banner-list" */
  '@/views/Admin/HomeBannerList.vue'
);
export const MutateHomeBanner = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-home-banner" */
  '@/views/Admin/MutateHomeBanner.vue'
);

// 'course-admin' + 'content-admin'
export const QuestionPoolList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-pool-list" */
  '@/views/Admin/QuestionPoolList.vue'
);
export const MutateQuestionPool = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question-pool" */
  '@/views/Admin/MutateQuestionPool.vue'
);
export const QuestionTagList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-tag-list" */
  '@/views/Admin/QuestionTagList.vue'
);
export const MutateQuestionTag = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question-tag" */
  '@/views/Admin/MutateQuestionTag.vue'
);
export const QuestionList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-list" */
  '@/views/Admin/QuestionList.vue'
);
export const MutateQuestion = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question" */
  '@/views/Admin/MutateQuestion.vue'
);
export const UploadQuestions = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "upload-questions" */
  '@/views/Admin/UploadQuestions.vue'
);
export const SubjectList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "subject-list" */
  '@/views/Admin/SubjectList.vue'
);
export const MutateSubject = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-subject" */
  '@/views/Admin/MutateSubject.vue'
);
export const YoutubeCourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "youtube-course-list" */
  '@/views/Admin/YoutubeCourseList.vue'
);
export const MutateYoutubeCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-youtube-course" */
  '@/views/Admin/MutateYoutubeCourse.vue'
);

// 'course-admin' only
export const CourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-list" */
  '@/views/Admin/CourseList.vue'
);
export const MutateCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-course" */
  '@/views/Admin/MutateCourse.vue'
);
export const InstructorList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "instructor-list" */
  '@/views/Admin/InstructorList.vue'
);
export const MutateInstructor = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-instructor" */
  '@/views/Admin/MutateInstructor.vue'
);
export const DynamicPageList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-dynamic-page-list" */
  '@/views/Admin/DynamicPageList.vue'
);
export const MutateDynamicPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-dynamic-page" */
  '@/views/Admin/MutateDynamicPage.vue'
);
export const FacebookSettings = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "facebook-settings" */
  '@/views/Admin/Settings/FacebookSettings.vue'
);
export const MaterialList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "material-list" */
  '@/views/Admin/MaterialList.vue'
);
export const MutateMaterial = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-material" */
  '@/views/Admin/MutateMaterial.vue'
);
export const MutateQuiz = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "-mutate-quiz" */
  '@/views/Admin/MutateQuiz.vue'
);

// Auth
export const AuthView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
export const LoginView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);

// Individual
export const UserView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-view" */
  '@/views/Individual/UserView.vue'
);
export const AccountView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-account-view" */
  '@/views/Individual/AccountView.vue'
);

// All
export const AdminTemplate = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-template" */
  '@/views/Admin/AdminTemplate.vue'
);
