/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Nov 12 2024
 */

import {
  RouteRecordRaw,
} from 'vue-router';
import {
  AccountView,
  AdminTemplate,
  AuthView,
  CouponList,
  CourseList,
  DynamicPageList,
  EnrollmentList,
  FacebookSettings,
  HomeBannerList,
  InstructorList,
  LoginView,
  MaterialList,
  MutateCoupon,
  MutateCourse,
  MutateDynamicPage,
  MutateHomeBanner,
  MutateInstructor,
  MutateMaterial,
  MutateNews,
  MutateQuestion,
  MutateQuestionPool,
  MutateQuestionTag,
  MutateQuiz,
  MutateSubject,
  MutateUser,
  MutateYoutubeCourse,
  NewsList,
  QuestionList,
  QuestionPoolList,
  QuestionTagList,
  StudentList,
  SubjectList,
  UploadQuestions,
  UserList,
  UserView,
  YoutubeCourseList,
} from './component';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    alias: '/',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: 'AccountView',
        component: AccountView,
        meta: {
          title: 'Account View',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'user-list',
        name: 'AdminList',
        component: UserList,
        meta: {
          title: 'Admin List',
        },
      },
      {
        path: 'user/add/:id',
        alias: 'user/edit/:id',
        name: 'MutateUser',
        component: MutateUser,
        meta: {
          title: 'Add/Edit Admin',
        },
      },
      {
        path: 'course-list',
        name: 'CourseList',
        component: CourseList,
        meta: {
          title: 'Course List',
        },
      },
      {
        path: 'course/add/:id',
        alias: 'course/edit/:id',
        name: 'MutateCourse',
        component: MutateCourse,
        meta: {
          title: 'Add/Edit Course',
        },
      },
      {
        path: 'quiz/add/:id',
        alias: 'quiz/edit/:id',
        props: (route) => ({
          id: route.params.id,
          courseId: route.query.courseId,
          program: route.query.program,
          subjectId: route.query.subjectId,
          chapterId: route.query.chapterId,
        }),
        name: 'MutateQuiz',
        component: MutateQuiz,
        meta: {
          title: 'Add/Edit Quiz',
        },
      },
      {
        path: 'instructor-list',
        name: 'InstructorList',
        component: InstructorList,
        meta: {
          title: 'Instructor List',
        },
      },
      {
        path: 'instructor/add/:id',
        alias: 'instructor/edit/:id',
        name: 'MutateInstructor',
        component: MutateInstructor,
        meta: {
          title: 'Add/Edit Instructor',
        },
      },
      {
        path: 'enrollment-list',
        name: 'EnrollmentList',
        component: EnrollmentList,
        meta: {
          title: 'Enrollment List',
        },
      },
      {
        path: 'student-list',
        name: 'StudentList',
        component: StudentList,
        meta: {
          title: 'Student List',
        },
      },
      {
        path: 'coupon-list',
        name: 'CouponList',
        component: CouponList,
        meta: {
          title: 'Coupon List',
        },
      },
      {
        path: 'coupon/add/:id',
        alias: 'coupon/edit/:id',
        name: 'MutateCoupon',
        component: MutateCoupon,
        meta: {
          title: 'Add/Edit Coupon',
        },
      },
      {
        path: 'home-banner-list',
        name: 'HomeBannerList',
        component: HomeBannerList,
        meta: {
          title: 'Home Banner List',
        },
      },
      {
        path: 'home-banner/add/:id',
        alias: 'home-banner/edit/:id',
        name: 'MutateHomeBanner',
        component: MutateHomeBanner,
        meta: {
          title: 'Add/Edit Home Banner',
        },
      },
      {
        path: 'subject-list',
        name: 'SubjectList',
        component: SubjectList,
        meta: {
          title: 'Subject List',
        },
      },
      {
        path: 'subject/add/:id',
        alias: 'subject/edit/:id',
        name: 'MutateSubject',
        component: MutateSubject,
        meta: {
          title: 'Add/Edit Subject',
        },
      },
      {
        path: 'youtube-course-list',
        name: 'YoutubeCourseList',
        component: YoutubeCourseList,
        meta: {
          title: 'Free Course List',
        },
      },
      {
        path: 'youtube-course/add/:id',
        alias: 'youtube-course/edit/:id',
        name: 'MutateYoutubeCourse',
        component: MutateYoutubeCourse,
        meta: {
          title: 'Add/Edit Free Course',
        },
      },
      {
        path: 'news-list',
        name: 'NewsList',
        component: NewsList,
        meta: {
          title: 'Notice List',
        },
      },
      {
        path: 'news/add/:id',
        alias: 'news/edit/:id',
        name: 'MutateNews',
        component: MutateNews,
        meta: {
          title: 'Add/Edit Notice',
        },
      },
      {
        path: 'dynamic-page-list',
        name: 'DynamicPageList',
        component: DynamicPageList,
        meta: {
          title: 'Dynamic Page List',
        },
      },
      {
        path: 'dynamic-page/add/:id',
        alias: 'dynamic-page/edit/:id',
        name: 'MutateDynamicPage',
        component: MutateDynamicPage,
        meta: {
          title: 'Add/Edit Dynamic Page',
        },
      },
      {
        path: 'material-list/:courseId',
        name: 'MaterialList',
        component: MaterialList,
        meta: {
          title: 'Material List',
        },
      },
      {
        path: 'material/add/:courseId',
        name: 'AddMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Add Material',
        },
      },
      {
        path: 'material/edit/:id',
        name: 'EditMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Edit Material',
        },
      },
      {
        path: 'question-list',
        name: 'QuestionList',
        component: QuestionList,
        meta: {
          title: 'Question List',
        },
      },
      {
        path: 'upload-questions',
        name: 'UploadQuestions',
        component: UploadQuestions,
        meta: {
          title: 'Upload Questions',
        },
      },
      {
        path: 'question/add/:id',
        alias: 'question/edit/:id',
        name: 'MutateQuestion',
        component: MutateQuestion,
        meta: {
          title: 'Add/Edit Question',
        },
      },
      {
        path: 'question-tag-list',
        name: 'QuestionTagList',
        component: QuestionTagList,
        meta: {
          title: 'Question Tag List',
        },
      },
      {
        path: 'question-tag/add/:id',
        alias: 'question-tag/edit/:id',
        name: 'MutateQuestionTag',
        component: MutateQuestionTag,
        meta: {
          title: 'Add/Edit Question Tag',
        },
      },
      {
        path: 'question-pool-list',
        name: 'QuestionPoolList',
        component: QuestionPoolList,
        meta: {
          title: 'Question Pool List',
        },
      },
      {
        path: 'question-pool/add/:id',
        alias: 'question-pool/edit/:id',
        name: 'MutateQuestionPool',
        component: MutateQuestionPool,
        meta: {
          title: 'Add/Edit Question Pool',
        },
      },
      // settings
      {
        path: 'settings/facebook',
        name: 'FacebookSettings',
        component: FacebookSettings,
        meta: {
          title: 'Facebook Settings',
        },
      },
    ],
  },
];
